import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { PiPlusBold } from "react-icons/pi";
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import type { CreateProjectMutationRequest, Project } from "@/api";
import { PageContent } from "@/components/Layout/PageContent";

import { PROJECTS_ARCHIVE_PATH, PROJECTS_ROUTES } from "../../routes";
import { useProjectsTableColumns } from "./use-projects-table-columns";

function ProjectsTable({
  projects,
  workspace_id,
  companyId,
}: {
  projects: Project[];
  createProject?: (project: CreateProjectMutationRequest) => void;
  workspace_id: string;
  companyId?: number;
}) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const columns = useProjectsTableColumns({
    companyId,
    workspace_id,
  });

  const { clientGridProps } = useClientDataGridBinding(projects);

  const isArchivePath: boolean =
    pathname === generatePath(PROJECTS_ARCHIVE_PATH, { workspace_id });

  return (
    <PageContent>
      <DataGrid columns={columns} {...clientGridProps} />
      {!isArchivePath && (
        <div
          className="tw-my-4 tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-4 tw-px-[7px] tw-py-[6px]"
          onClick={() =>
            history.push(
              generatePath(`${url}${PROJECTS_ROUTES.details}`, {
                project_id: "new",
              }),
            )
          }
        >
          <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
          <div className="tw-neutral-300 tw-text-sm">Add Project...</div>
        </div>
      )}
    </PageContent>
  );
}

export default ProjectsTable;
