import { createComparatorById, IconButton, LinkIconButton } from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import type { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { PiBoxArrowUpBold, PiCaretRightBold } from "react-icons/pi";
import { generatePath, Link } from "react-router-dom";

import type { Project, TaskReference, User } from "@/api";
import { useUpdateProject } from "@/api/queries/projects";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import ProjectRowContextMenu from "@/features/projects/components/ProjectsTable/ProjectRowContextMenu";
import mainStore from "@/stores/Main";

import { PROJECT_TASKS_PATH } from "../../routes";

const getCompletedTasksCount = (project: Project | undefined) =>
  project?.tasks?.filter(
    (taskRef) => (taskRef as TaskReference).status.toLowerCase() === "done",
  ).length || 0;

export function useProjectsTableColumns({
  companyId,
  workspace_id,
}: {
  companyId?: number;
  workspace_id: string;
}) {
  const { data: users } = useCompanyUsers({ companyId: Number(companyId) });

  const { list: workspaces } = mainStore.workspaces;
  const { mutateAsync: updateProject } = useUpdateProject({});

  return useMemo(
    (): ColDef<Project>[] => [
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        cellRenderer: (props: { data: Project }) => (
          <Link
            to={(location) => ({
              pathname: generatePath(`${PROJECT_TASKS_PATH}`, {
                workspace_id,
                project_id: props.data.id,
              }),
              state: { from: location.pathname },
            })}
          >
            <span className="tw-mx-2 tw-cursor-pointer tw-font-medium tw-text-neutral-300 hover:tw-underline hover:tw-underline-offset-4">
              {props.data.name}
            </span>
          </Link>
        ),

        type: "string",
        width: 300,
        minWidth: 200,
        flex: 2,
      },
      {
        headerName: "Progress",
        comparator: (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _foo: any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          __bar: any,
          nodeA: { data: Project | undefined },
          nodeB: { data: Project | undefined },
        ) => {
          const totalTasksA = nodeA.data?.tasks.length || 0;
          const totalTasksB = nodeB.data?.tasks.length || 0;

          return (
            getCompletedTasksCount(nodeA.data) / totalTasksA -
            getCompletedTasksCount(nodeB.data) / totalTasksB
          );
        },
        cellRenderer: (props: { data: Project }) => {
          const total = props.data.tasks.length;
          const completed = getCompletedTasksCount(props.data);

          return (
            <div className="tw-mx-2 tw-flex">
              <span className="tw-font-medium tw-text-neutral-300">
                {completed.toString()}/{total}
              </span>
              <span className="tw-relative tw-ml-5">
                <CircularProgressbar
                  className="tw-h-6 tw-w-6"
                  value={Math.round((completed / total) * 100) || 0}
                  strokeWidth={15}
                  styles={buildStyles({
                    pathColor: "#6161C3",
                    trailColor: "#E4E4EB",
                  })}
                />
              </span>
            </div>
          );
        },
        width: 90,
        minWidth: 90,
        flex: 0.5,
      },
      {
        headerName: "Description",
        field: "description",
        cellRenderer: "textRenderCell",
        type: "string",
        flex: 2,
        minWidth: 200,
      },
      {
        headerName: "Created by",
        field: "created_by_id",
        comparator: createComparatorById({
          data: users || [],
          propertyKey: "full_name",
        }),
        cellRenderer: (props: { data: Project }) => {
          const createdByUser: User | undefined = users?.find(
            (user) => user.id === props.data.created_by_id,
          );

          return createdByUser ? (
            <div className="tw-mx-2 tw-flex tw-items-center tw-space-x-2 tw-font-medium tw-text-neutral-300">
              <Avatar colorSeed={createdByUser.icon_color_index}>
                {createdByUser.initials}
              </Avatar>
              <span>{createdByUser?.full_name}</span>
            </div>
          ) : (
            <Avatar colorSeed={0}>N/A</Avatar>
          );
        },
        flex: 1,
        minWidth: 180,
      },
      {
        headerName: "Workspace",
        field: "workspace_id",
        comparator: createComparatorById({
          data: workspaces,
          propertyKey: "name",
        }),
        cellRenderer: ({ value }: { value: number | null }) => {
          const workspace = workspaces.find((ws) => ws.id === value);
          return (
            <div className="tw-mx-2 tw-flex tw-items-center tw-gap-2 tw-font-medium tw-text-neutral-300">
              <img
                className="tw-w-[20px] tw-rounded"
                src={workspace ? getWorkspaceLogo(workspace).logo : undefined}
              />
              <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                {workspace?.name}
              </span>
            </div>
          );
        },
        flex: 1,
        minWidth: 180,
      },
      {
        cellRenderer: (props: { data: Project }) => (
          <div className="tw-flex tw-justify-center tw-space-x-1">
            {props.data.archived_at ? (
              <IconButton
                Icon={PiBoxArrowUpBold}
                color="tertiary"
                size="md"
                onClick={() =>
                  updateProject({
                    projectId: props.data.id,
                    data: { project: { archived_at: null } },
                  })
                }
                data-testid="unarchive-project"
                data-tooltip-id="tooltip"
                data-tooltip-place="left"
                data-tooltip-content="Unarchive project"
              />
            ) : (
              <ProjectRowContextMenu values={props.data} />
            )}
            <LinkIconButton
              Icon={PiCaretRightBold}
              color="primary"
              size="md"
              to={(location) => ({
                pathname: generatePath(`${PROJECT_TASKS_PATH}`, {
                  workspace_id,
                  project_id: props.data.id,
                }),
                state: { from: location.pathname },
              })}
            />
          </div>
        ),
        width: 90,
        minWidth: 90,
        pinned: "right",
      },
    ],
    [updateProject, users, workspaces, workspace_id],
  );
}
