import type { DataWithIdAndCustomFields } from "@themis/ui";
import { useParams } from "react-router";

import type { RecordTypePath } from "@/api";
import type { FilterFieldData } from "@/components/FilterSelect/utils/get-filters-field-data";

import { useSearchParams } from "../useSearchParams";
import { saveFiltersToLocalStorage } from "./save-filters-to-local-storage/save-filters-to-local-storage";
import { stringifyFilterValue } from "./stringify-filter-value/stringify-filter-value";
import type { Filter, Sorting } from "./types";
import { useFiltersSortValues } from "./use-filters-sort-values/use-filters-sort-values";

/**
 * Used to manage filters and sorting with the new queryParam format, exp. `?filters[status][any]=In Progress,Done&sort_by=status[asc]`
 */
export default function useFilterSort<T extends DataWithIdAndCustomFields>(
  fieldsData: FilterFieldData<T>,
  recordType: RecordTypePath,
) {
  const { workspace_id: workspaceId } = useParams<{ workspace_id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, sorting, listRequestQueryParams } = useFiltersSortValues({
    fieldsData,
    recordType,
  });

  /**
   * Applies filters, removes filters, and adds sort to the search parameters in the URL.
   *
   * @param {Object} params - The parameters for applying filters and sorting.
   * @param {Partial<Record<keyof T, Filter>>} [params.filtersToApply={}] - The filters to apply, where each key is a field name and the value is a filter object.
   * @param {(keyof T)[]} [params.filtersToRemove] - The filters to remove, where each item is a field name.
   * @param {Sorting<T> | null} [params.sort] - The sorting configuration, where the columnKey is the field to sort by and direction is the sort direction. If null, sorting will be cleared.
   */
  function applyFilterSort({
    filtersToApply = {},
    filtersToRemove,
    sort,
  }: {
    filtersToApply?: Partial<Record<keyof T, Filter>>;
    filtersToRemove?: (keyof T)[];
    sort?: Sorting<T> | null;
  }) {
    // Apply the filters provided in filtersToApply
    const appliedFilters = Object.keys(filtersToApply).reduce(
      (acc, fieldName) => {
        const filter = filtersToApply[fieldName as keyof T];
        if (!filter) {
          return acc;
        }

        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filter.operand]: stringifyFilterValue(filter),
            },
          },
        };
      },
      { filters: {} },
    );

    // Remove the filters specified in filtersToRemove
    const removedFilters = filtersToRemove?.reduce(
      (acc, fieldName) => {
        if (!filters[fieldName]) {
          return acc;
        }

        const filterOperand = filters[fieldName]?.operand;

        if (!filterOperand) {
          return acc;
        }

        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filterOperand]: undefined,
            },
          },
        };
      },
      { filters: {} },
    );

    // Handle sorting parameters
    const sortedParams = (() => {
      // Clear existing sort parameters
      const clearedSortParams = Object.keys(searchParams).reduce(
        (acc, key) => (key === "sort_by" ? { ...acc, [key]: undefined } : acc),
        {},
      );
      // Apply new sort parameters if provided
      if (sort || sort === null) {
        return {
          ...clearedSortParams,
          ...(sort ? { sort_by: { [sort.columnKey]: sort.direction } } : {}),
        };
      }

      return {};
    })();

    // Combine all new search parameters
    const newSearchParamObject = {
      filters: {
        ...appliedFilters.filters,
        ...removedFilters?.filters,
      },
      ...sortedParams,
    };

    saveFiltersToLocalStorage({
      filters: newSearchParamObject.filters,
      recordType,
      workspaceId,
    });

    // Update the search parameters in the URL
    setSearchParams(
      {
        ...searchParams,
        ...newSearchParamObject,
        filters: {
          ...(typeof searchParams.filters === "object" && searchParams.filters),
          ...newSearchParamObject.filters,
        },
      },
      true,
    );
  }

  function clearAllFilters() {
    const clearedFilters = Object.keys(filters).reduce(
      (acc, fieldName) => {
        const filter = filters[fieldName as keyof T];
        if (!filter) {
          return acc;
        }
        const filterOperand = filter.operand;
        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filterOperand]: undefined,
            },
          },
        };
      },
      { filters: {} },
    );

    saveFiltersToLocalStorage({
      filters: clearedFilters.filters,
      recordType,
      workspaceId,
    });
    setSearchParams({ ...searchParams, ...clearedFilters }, true);
  }

  return {
    filters: Object.keys(filters).length > 0 ? filters : undefined,
    applyFilterSort,
    clearAllFilters,
    sorting,
    listRequestQueryParams,
  };
}
